import React from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import OptionsList from './optionsList.json';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useState, useEffect } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './darkcss.css';

function App() {

  const [images, setImages] = useState([])
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    if (window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setDarkMode(true)
    } else {
      setDarkMode(false)
    }
  }, [])

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [darkMode])

  useEffect(() => {
    console.log(images)
  }, [images])

  const Options = () => {
    let keys = [];
    for (let key in OptionsList) {
      keys.push(key);
    }
    return (
      <>
        {
          keys.map((item, index) => {
            return (
              <>
                <Typography variant="h5" gutterBottom component="div" style={{ 'color': darkMode ? 'white' : 'black' }}>
                  {OptionsList[item]['title']}
                </Typography>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  {
                    Array.from(Object.keys(OptionsList[item]['options'])).map(option => {
                      return (
                        <Button onClick={() => { images.includes(option) ? setImages(images.filter(item => item !== option)) : setImages([option, ...images]) }} color={images.includes(option) ? 'success' : 'primary'}>
                          {OptionsList[item]['options'][option]['title']}
                        </Button>
                      )
                    })
                  }
                </ButtonGroup>
              </>
            )
          })
        }
      </>
    )
  }

  const ShowImages = () => {

    return (

      <ImageList cols={4} gap={8} style={{ 'flex': 1, 'flex-direction': 'row' }}>
        {images.map((item) => {
          return (
            <ImageListItem key={item} style={{ 'flex': 1 }}>
              <img
                src={`/img/${item}.png`}
                loading="lazy"
                style={{ 'objectFit': 'contain' }}
                alt={item}
              />
            </ImageListItem>
          )
        })}
      </ImageList>


    )
  }


  return (
    <div>
      <Container maxWidth="md" style={{ 'marginTop': '3vh', 'marginBottom': '3vh' }}>
        <Options />
      </Container>
      <Container>
        <ShowImages />
      </Container>
    </div>
  );
}

export default App;
